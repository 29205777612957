.listzone {
  background-color: var(--bs-indigo);
  padding: 30px;
  color: white;
  font-size: small;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  line-height: 25px;
}
.listzone img {
  width: 50px !important;
  border-radius: 3px;
}
.listzone > ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.listzone li div {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: lowercase;
  border: solid 1px white;
  padding: 20px;
  background-color: var(--bs-indigo);
  color: white;
  border-radius: 4px;
  justify-content: space-between;
}
.dropzone {
  background-color: var(--bs-primary);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 30px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.uploadzone {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  margin: 40px 0;
}
.uploadedfile {
  display: flex;
  width: 100%;
  flex-direction: row;
  border: solid 1px var(--bs-warning);
  border-radius: 9px;
  padding: 10px;
  margin-bottom: 10px;
  gap: 10px;
}
.uploadedfile > div {
  width: 100%;
}
.uploadedfile p {
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.uploadedfile .status {
  display: flex;
  align-items: center;
  gap: 20px;
}
.uploadedfile .progress {
  width: 100%;
  height: 3px;
  background-color: var(--bs-warning);
  border-radius: 2px;
}
.uploadedfile figure {
  border-radius: 4px;
  width: 60px;
  overflow: hidden;
  margin: 0;
}
